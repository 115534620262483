import { MediaContentShowcaseItem } from "@onnit-js/ui/components/module/MediaContentShowcase";

const ingredients: MediaContentShowcaseItem[] = [
    {
        image: "",
        imageWidth: 560,
        imageHeight: 560,
        title: "The Onnit Myco-Immune Blend",
        summary:
            " A combination of mushrooms, including Chaga, a fungus that grows on the bark of birch trees in cold areas, and has been used for generations.",
        detail:
            'These mushrooms offer polysaccharides, compounds (including beta-glucan) that help the immune system and support healthy gut bacteria.<span class="oui-sup">†</span>',
    },
    {
        image: "",
        imageWidth: 560,
        imageHeight: 560,
        title: "Onnit Nutri-Immune Blend",
        summary: "A blend of mushrooms and herbal extracts.",
        detail: 'Supports seasonal wellness.<span class="oui-sup">†</span>',
    },
];

export default ingredients;
