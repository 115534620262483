import React, { useState } from "react";
import ThemeProvider from "@onnit-js/ui/components/themes/ThemeProvider";
import GlobalStyle from "@onnit-js/ui/components/themes/GlobalStyle";
import theme from "@onnit-js/ui/components/themes/light";
import Box from "@onnit-js/ui/components/box/Box";
import { Text, List, ListItem, Sup } from "@onnit-js/ui/components/text";
import useOnnitContext from "@onnit-js/ui/hooks/useOnnitContext";
import { Img, Picture } from "@onnit-js/ui/components/image";
import ProductImageGrid from "@onnit-js/ui/components/module/ProductImageGrid";
import ImageContentPortrait from "@onnit-js/ui/components/module/ImageContentPortrait";
import MediaContentShowcase from "@onnit-js/ui/components/module/MediaContentShowcase";
import TwelveColumn from "@onnit-js/ui/components/module/grid/12/Grid";
import usePageData from "../../hooks/usePageData";
import SupplementFacts from "../../components/common/SupplementFacts";
import ingredients from "./ingredients";
import DefaultProductPageTop from "../../components/common/layouts/DefaultProductPageTop";
import UsageAndDisclaimers from "../../components/common/UsageAndDisclaimers";
import config from "../../config/config";

export default function Page() {
    const onnitContext = useOnnitContext();
    const { pageData } = usePageData();
    // Used to set current products supplement facts image.
    const [currentPID, setCurrentPID] = useState<number | undefined>();

    if (!pageData || !onnitContext) {
        return null;
    }

    const IMG_URL = `${onnitContext.url.cdn.static_images}/product-page/shroomtech-immune`;
    const { IMG_PARAMS } = config;

    return (
        <ThemeProvider theme={theme}>
            <>
                <GlobalStyle />

                <DefaultProductPageTop
                    onProductChange={(product) => {
                        setCurrentPID(product.id);
                    }}
                >
                    <ProductImageGrid variant="layout3">
                        <Img
                            alt="30 count Bottle of Shroom Tech IMMUNE "
                            src={`${IMG_URL}/2023-STI-Wpage-Gallery-1-920x920.jpg${IMG_PARAMS}`}
                            lazyLoad={false}
                        />
                        <Img
                            alt="90 count Bottle of Shroom Tech IMMUNE "
                            src={`${IMG_URL}/2023-STI-Wpage-Gallery-2-920x920.jpg${IMG_PARAMS}`}
                            lazyLoad={false}
                        />
                        <Picture
                            lazyLoad={false}
                            width={1943}
                            height={873}
                            alt="Bottle of Shroom Tech IMMUNE on green table"
                            src={[
                                `${IMG_URL}/2023-STI-Wpage-Gallery-3-920x920.jpg${IMG_PARAMS}`,
                                `${IMG_URL}/2023-STI-Wpage-Gallery-3-1940x873.jpg${IMG_PARAMS}`,
                            ]}
                        />
                        <Img
                            alt="chaga mushrooms"
                            src={`${IMG_URL}/2023-STI-Wpage-Gallery-4-920x920.jpg?q=100`}
                            lazyLoad={false}
                        />
                        <Img
                            alt="people being active"
                            src={`${IMG_URL}/2023-STI-Wpage-Gallery-5-920x920.jpg?q=100`}
                            lazyLoad={false}
                        />
                    </ProductImageGrid>
                </DefaultProductPageTop>

                <Box bg="#161616" mt={[0, 0, 6, 8]} py={[6, 6, 6, 8]}>
                    <TwelveColumn display={["none", "none", "grid"]} mb={4}>
                        <Box gridColumn={["1 / span 6", "1 / span 6", "1 / span 6", "2 / span 7"]} px={4}>
                            <Text as="h2" color="grays.3" typeStyle="text02">
                                Shroom Tech
                                <Sup variant="reg" /> IMMUNE
                            </Text>
                        </Box>
                    </TwelveColumn>
                    <TwelveColumn display={["block", "block", "grid"]} mb={[0, 0, 6]}>
                        <Box
                            gridColumn={["7 / span 6", "7 / span 6", "7 / span 6", "7 / span 5"]}
                            px={4}
                            mb={[8, 8, 0]}
                        >
                            <Img
                                src={`${IMG_URL}/2023-STI-Wpage-Product-Hero-1920x1080.jpg${IMG_PARAMS}`}
                                alt="Man looking at the airport departures board at the airport"
                            />
                        </Box>
                        <Box
                            gridColumn={["1 / span 6", "1 / span 6", "1 / span 6", "2 / span 5"]}
                            display={["block", "block", "flex"]}
                            alignItems="center"
                            px={4}
                        >
                            <Text
                                display={["block", "block", "none"]}
                                as="h2"
                                color="grays.3"
                                typeStyle="text02"
                                my={3}
                            >
                                Shroom Tech
                                <Sup variant="reg" /> IMMUNE
                            </Text>

                            <Text as="p" typeStyle="title01" color="#8DC675" mb={2}>
                                Shroom Tech
                                <Sup variant="reg" /> <Box as="br" display={["none", "none", "block"]} />
                                Immune Keeps <Box as="br" display={["none", "none", "block"]} />
                                Your Immune <Box as="br" display={["none", "none", "block"]} />
                                System Online
                                <Sup />
                            </Text>
                        </Box>
                    </TwelveColumn>

                    <TwelveColumn display={["block", "block", "grid"]} mb={8}>
                        <Box
                            gridColumn={["1 / span 6", "1 / span 6", "1 / span 6", "2 / span 7"]}
                            px={4}
                            mb={[6, 6, 6, 8]}
                        >
                            <Text as="p" color="white" typeStyle="text02" mb={4}>
                                Using a combination of mushroom and herbal extracts, Shroom Tech
                                <Sup variant="reg" /> IMMUNE supports the body's natural defenses.
                                <Sup />
                            </Text>
                        </Box>
                        <Box gridColumn={["2 / span 10", "2 / span 10", "2 / span 10", "3 / span 10"]} px={4} mb={6}>
                            <Text as="p" typeStyle="title01" color="#8DC675" mb={4}>
                                Shroom Tech
                                <Box as="br" display={["block", "block", "block", "none"]} />
                                <Sup variant="reg" /> IMMUNE is for:
                            </Text>
                            <List>
                                <ListItem typeStyle="text02" color="white">
                                    Frequent travelers
                                </ListItem>
                                <ListItem typeStyle="text02" color="white">
                                    Those who meet new people often
                                </ListItem>
                                <ListItem typeStyle="text02" color="white">
                                    People who have to deal with crowds
                                </ListItem>
                                <ListItem typeStyle="text02" color="white">
                                    Athletes on the field and in the gym
                                </ListItem>
                            </List>
                        </Box>
                    </TwelveColumn>

                    <TwelveColumn mb={4}>
                        <Text
                            px={[4, 4, 5, 6]}
                            gridColumn={["1 / -1", "1 / -1", "1 / -1", "2 / span 12"]}
                            role="heading"
                            aria-level={2}
                            as="p"
                            typeStyle="title02"
                            color="white"
                        >
                            Key Blends
                        </Text>
                    </TwelveColumn>

                    <MediaContentShowcase
                        px={[0, 0, 0, 6]}
                        items={ingredients}
                        showImages={false}
                        textColor="grays.3"
                        activeColor="white"
                        activeTextColor="white"
                    />
                </Box>

                <ImageContentPortrait
                    my={[6, 6, 6, 8]}
                    reverse
                    image={(
                        <Img
                            src={`${IMG_URL}/2023-STI-Wpage-How.jpg${IMG_PARAMS}`}
                            alt="bottle of Shroom Tech IMMUNE"
                        />
                    )}
                    content={(
                        <Box display="flex" flexDirection="column" height="100%" justifyContent="center">
                            <Text typeStyle="title02" mb={[4, 4, 6]}>
                                How Shroom Tech <Sup variant="reg" /> IMMUNE Works
                            </Text>
                            <Text as="p" typeStyle="text02" color="grays.6">
                                Two ingredient blends—the Onnit Myco-Immune Blend and Nutri-Immune Blend—work to support
                                the immune system.
                                <Sup />
                            </Text>
                        </Box>
                    )}
                />

                <UsageAndDisclaimers supplementWarning supplementFacts={<SupplementFacts currentPID={currentPID} />} />
            </>
        </ThemeProvider>
    );
}
